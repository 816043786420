function strBooleanIsTrue(str) {
  return str === 'true';
}

const config = {
  logRocketEnabled: strBooleanIsTrue(process.env.GATSBY_LOG_ROCKET_ENABLED),
  logRocketProject: process.env.GATSBY_LOG_ROCKET_PROJECT,
  sendingSlackEnabled: strBooleanIsTrue(process.env.SEND_SLACK_ENABLED),
  slackWebhookUrl: process.env.SLACK_WEBHOOK,
};

export { config };

import React from 'react';

import { GatsbyImagesProvider } from './src/providers/images';

// included in every page because of a weird bug
// https://stackoverflow.com/questions/69717248/gatsby-layout-in-wrappageelement-does-not-update-state
// import Layout from './src/components/layout/layout';

// Pass all props (hence the ...props)
// to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element }) => <GatsbyImagesProvider>{element}</GatsbyImagesProvider>;

export default wrapPageElement;

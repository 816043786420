import React from 'react';

import { LoggingProvider } from './src/providers/logging';
import { UserPermissionsProvider } from './src/providers/permissions';

// Instantiating store in `wrapRootElement` handler ensures:
//  - there is fresh store for each SSR page
//  - it will be called only once in browser, when React mounts
const wrapWithProvider = ({ element }) => (
  <UserPermissionsProvider>
    <LoggingProvider>{element}</LoggingProvider>
  </UserPermissionsProvider>
);

export default wrapWithProvider;

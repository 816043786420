import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTrackingPermission } from '../hooks';
import { config } from '../utils';

interface LoggingContext {
  loggingEnabled: boolean;
  logLevel: string;
}

const LoggingContext = React.createContext<LoggingContext>({
  loggingEnabled: true,
  logLevel: 'debug',
});

const LoggingProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const trackingPermissionGranted = useTrackingPermission();
  const [logrocketStarted, setLogrocketStarted] = useState(false);

  useEffect(() => {
    if (trackingPermissionGranted && !logrocketStarted) {
      import('logrocket').then(({ default: LogRocket }) => {
        if (config.logRocketEnabled) {
          const logConfig = { network: { isEnabled: false } };
          LogRocket.init(config.logRocketProject, logConfig);
          setLogrocketStarted(true);
        }
      });
    }
  }, [trackingPermissionGranted, logrocketStarted]);

  return (
    <LoggingContext.Provider value={{ loggingEnabled: true, logLevel: 'debug' }}>{children}</LoggingContext.Provider>
  );
};

export { LoggingProvider, LoggingContext };

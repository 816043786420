import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

interface UserPermissionsContext {
  trackingPermissionGranted: boolean;
  ofAgeVerification: {
    ofAgeAccepted: boolean;
    handleOfAgeAccepted: () => void;
    handleOfAgeRejected: () => void;
  };
}

const UserPermissionsContext = React.createContext<UserPermissionsContext>({
  trackingPermissionGranted: true,
  ofAgeVerification: {
    ofAgeAccepted: true,
    handleOfAgeAccepted: () => undefined,
    handleOfAgeRejected: () => undefined,
  },
});

const localStorageOfAgeId = 'ofAgeAccepted';

const UserPermissionsProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [ofAgeAccepted, setOfAgeAccepted] = useState(true);

  const handleOfAgeAccepted = useCallback(() => {
    setOfAgeAccepted(true);
    localStorage.setItem(localStorageOfAgeId, 'true');
  }, []);

  const handleOfAgeRejected = useCallback(() => {
    setOfAgeAccepted(false);
    localStorage.removeItem(localStorageOfAgeId);
    window.location.replace('https://lokabrews.medium.com');
  }, []);

  useEffect(() => {
    const storedValue = localStorage.getItem(localStorageOfAgeId);
    if (storedValue === 'true') {
      setOfAgeAccepted(true);
    } else {
      setOfAgeAccepted(false);
    }
  }, []);

  return (
    <UserPermissionsContext.Provider
      value={{
        trackingPermissionGranted: true,
        ofAgeVerification: {
          ofAgeAccepted,
          handleOfAgeAccepted,
          handleOfAgeRejected,
        },
      }}
    >
      {children}
    </UserPermissionsContext.Provider>
  );
};

export { UserPermissionsProvider, UserPermissionsContext };

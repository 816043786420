import { useStaticQuery, graphql } from 'gatsby';
import type { Beer } from '../types';

const useBeers = () => {
  const {
    allBeers: { beers },
  } = useStaticQuery(graphql`
    query AllBeers {
      allBeers {
        beers: nodes {
          alc
          analyticalDescription
          artImg
          ibu
          ibuImg
          bottleImg
          bottleShadowImg
          color
          colorImg
          description
          introduction
          name
          pairingTeaser
          pouringImg
          slug
          sub
          titleImg
          awards {
            img
            name
            year
          }
          ingredients {
            alt
            img
          }
          sensoryDescription
        }
      }
    }
  `);

  return beers as Array<Beer>;
};

export { useBeers };

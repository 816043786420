import React, { PropsWithChildren } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface ImageFile {
  relativePath: string;
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData;
  };
}

interface AllFile {
  allFile: {
    nodes: Array<ImageFile>;
  };
}

interface ImagesContext {
  images: Array<ImageFile>;
}

const ImagesContext = React.createContext<ImagesContext>({ images: [] });

const GatsbyImagesProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const data: AllFile = useStaticQuery(graphql`
    query Images {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const context = { images: data.allFile.nodes };

  return <ImagesContext.Provider value={context}>{children}</ImagesContext.Provider>;
};

export { ImagesContext, GatsbyImagesProvider };

import type { Beer } from '../types';

const getTextColorClassForBeer = (beer: Beer, query = '') => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return `${query}text-witbier`;
    case 'saison':
      return `${query}text-saison`;
    case 'scotch':
      return `${query}text-scotch`;
    default:
      'text-secondary';
  }
};

const getBgColorClassForBeer = (beer: Beer, query = '', light = false) => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return light ? `${query}bg-witbier-background` : `${query}bg-witbier`;
    case 'saison':
      return light ? `${query}bg-saison-background` : `${query}bg-saison`;
    case 'scotch':
      return light ? `${query}bg-scotch-background` : `${query}bg-scotch`;
    default:
      'text-secondary';
  }
};

const getBorderColorClassForBeer = (beer: Beer, query = '') => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return `${query}border-witbier`;
    case 'saison':
      return `${query}border-saison`;
    case 'scotch':
      return `${query}border-scotch`;
    default:
      'border-secondary';
  }
};

const getBrewingImgPathForBeer = (beer: Beer, useVertical: boolean) => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return useVertical
        ? 'beers/brewing/witbier_brewing_vertical.png'
        : 'beers/brewing/witbier_brewing_horizontal.png';
    case 'saison':
      return useVertical ? 'beers/brewing/saison_brewing_vertical.png' : 'beers/brewing/saison_brewing_horizontal.png';
    case 'scotch':
      return useVertical ? 'beers/brewing/scotch_brewing_vertical.png' : 'beers/brewing/scotch_brewing_horizontal.png';
    default:
      return useVertical
        ? 'beers/brewing/witbier_brewing_vertical.png'
        : 'beers/brewing/witbier_brewing_horizontal.png';
  }
};

const getPouringImgPathForBeer = (beer: Beer, useSquare: boolean) => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return useSquare ? 'beers/pouring/witbier_pouring_square.png' : 'beers/pouring/witbier_pouring_horizontal.png';
    case 'saison':
      return useSquare ? 'beers/pouring/saison_pouring_square.png' : 'beers/pouring/saison_pouring_horizontal.png';
    case 'scotch':
      return useSquare ? 'beers/pouring/scotch_pouring_square.png' : 'beers/pouring/scotch_pouring_horizontal.png';
    default:
      return useSquare ? 'beers/pouring/witbier_pouring_square.png' : 'beers/pouring/witbier_pouring_horizontal.png';
  }
};

const getFoodPairingImgPathForBeer = (beer: Beer) => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return 'beers/pairing/witbier_pairing.jpg';
    case 'saison':
      return 'beers/pairing/saison_pairing.jpg';
    case 'scotch':
      return 'beers/pairing/scotch_pairing.jpg';
    default:
      return 'beers/pairing/witbier_pairing.jpg';
  }
};

const getWheelOneImgPathForBeer = (beer: Beer) => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return 'beers/wheels/witbier_wheel_1.png';
    case 'saison':
      return 'beers/wheels/saison_wheel_1.png';
    case 'scotch':
      return 'beers/wheels/scotch_wheel_1.png';
    default:
      return 'beers/wheels/witbier_wheel_1.png';
  }
};

const getWheelTwoImgPathForBeer = (beer: Beer) => {
  switch (beer.slug) {
    case 'witbier-blanche':
      return 'beers/wheels/witbier_wheel_1.png';
    case 'saison':
      return 'beers/wheels/saison_wheel_1.png';
    case 'scotch':
      return 'beers/wheels/scotch_wheel_1.png';
    default:
      return 'beers/wheels/witbier_wheel_1.png';
  }
};

export {
  getTextColorClassForBeer,
  getBgColorClassForBeer,
  getBorderColorClassForBeer,
  getBrewingImgPathForBeer,
  getPouringImgPathForBeer,
  getFoodPairingImgPathForBeer,
  getWheelOneImgPathForBeer,
  getWheelTwoImgPathForBeer,
};

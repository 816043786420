import { useState, useEffect } from 'react';

const useBreakpoints = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isSm: width <= 640,
    isMd: width > 640 && width <= 1024,
    isLg: width > 1024,
    isXl: width > 1280,
    is2Xl: width > 1536,
  };
};

export { useBreakpoints };

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-beers-tsx": () => import("./../../../src/pages/beers.tsx" /* webpackChunkName: "component---src-pages-beers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-experience-tsx": () => import("./../../../src/pages/experience.tsx" /* webpackChunkName: "component---src-pages-experience-tsx" */),
  "component---src-pages-food-pairing-tsx": () => import("./../../../src/pages/food-pairing.tsx" /* webpackChunkName: "component---src-pages-food-pairing-tsx" */),
  "component---src-pages-glassware-tsx": () => import("./../../../src/pages/glassware.tsx" /* webpackChunkName: "component---src-pages-glassware-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-unsubscribed-tsx": () => import("./../../../src/pages/newsletter/unsubscribed.tsx" /* webpackChunkName: "component---src-pages-newsletter-unsubscribed-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-stories-tsx": () => import("./../../../src/pages/stories.tsx" /* webpackChunkName: "component---src-pages-stories-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-beer-tsx": () => import("./../../../src/templates/beer.tsx" /* webpackChunkName: "component---src-templates-beer-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */)
}


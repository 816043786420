import { useEffect, useRef } from 'react';

const useHasUnmounted = () => {
  const ref = useRef(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    };
  }, []);

  return ref;
};

export { useHasUnmounted };

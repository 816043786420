import { useI18next } from 'gatsby-plugin-react-i18next';

interface LocalizationData {
  locale: string;
  defaultLocale: string;
}

const useLocalization = (): LocalizationData => {
  const localization = useI18next();

  return {
    locale: localization.language,
    defaultLocale: localization.defaultLanguage,
  };
};

export { useLocalization };

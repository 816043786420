import { useMemo } from 'react';
import type { Beer } from '../types';
import { useBeers } from './useBeers';

const useOtherBeers = (beer: Beer) => {
  const beers = useBeers();

  const otherBeers: Array<Beer> = useMemo(() => {
    return beers.filter((b) => b.slug !== beer.slug);
  }, [beers, beer]);

  return otherBeers;
};

export { useOtherBeers };
